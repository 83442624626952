<script setup>
import { computed } from 'vue';
import { useRouter } from 'nuxt/app';
import { useListingStore } from '~/stores/account/listings.store';
import ListingCategories from './partials/categories.vue';
import ListingAddFrom from './partials/add-form.vue'

const listingsStore = useListingStore()
const router = useRouter()
const localePath = useLocalePath()

await listingsStore.fetchCategories()

const form = computed(() => listingsStore.formGetter())

const showPreviewBtn = () => {
    return !!Object.keys(form.value)?.length
}

const categories = computed(() => listingsStore.categoriesGetter())

const preview = async () => {
    await listingsStore.addListing()
        .then((res) => {
            listingsStore.saveListingInLocalStorage()
            router.push({ path: localePath({ name: 'listings.preview' }) });
        })
        .catch(err => {
            const errors = err?.response?.data || [];
            listingsStore.setAddListingErrors(errors || []);
        })
}
</script>

<template>
    <div class="relative">
        <header class="sticky top-0 z-10">
            <div class="px-4 h-20 bg-indigo-900 flex items-center justify-between text-white relative">
                <div class="flex items-center">
                    <NuxtLink :to="localePath({ name: 'home' })"> <i class="fa-solid fa-chevron-left fa-xl"></i> </NuxtLink>
                    <h1 class="absolute left-1/2 -translate-x-1/2 text-center text-white text-md font-bold">
                        {{ $t("client.add_listing") }}
                    </h1>
                </div>
            </div>
        </header>

        <main class="mb-32 px-4">
            <listing-categories></listing-categories>
            <listing-add-from></listing-add-from>
        </main>
        <div 
            v-if="showPreviewBtn()"
            class="fixed bottom-0 bg-white p-4 w-full"
        >
            <div class="flex items-center gap-3">
                <a 
                    class="bg-indigo-900 text-white rounded-lg p-3 capitalize w-full text-center"
                    @click="preview"
                >
                    {{ $t('client.preview_ad') }}
                </a>
            </div>
        </div>
    </div>
</template>